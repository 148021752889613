<template>
  <v-container v-if="validUser">
    <v-dialog v-if="showPatientReport" v-model="showPatientReport" scrollable>
      <PatientHistoryDialog
        v-model="showPatientReport"
        :assessment="formattedAssessment"
        :results="results"
      />
    </v-dialog>
    <v-row>
      <v-col cols="auto">
        <v-switch
          v-model="showCompletedAppointments"
          label="Show completed appointments"
        ></v-switch>
      </v-col>
      <v-spacer />
      <v-col cols="auto">
        <v-select
          v-model="featureFlag"
          :items="['EBRD', 'None']"
          @change="updateFeatureFlags"
        />
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="appointments"
          :loading="loading"
          :sort-by="['createdAt']"
          :sort-desc="[true]"
          @click:row="showResults"
          style="cursor: pointer"
        >
          <template v-slot:[`item.assessmentCompletedAt`]="{ item }">
            <span>
              {{ convertDate(item.assessmentCompletedAt) }}
            </span>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            <span>
              {{ convertDate(item.createdAt) }}
            </span>
          </template>
          <template v-slot:[`item.appointmentCompleted`]="{ item }">
            <v-simple-checkbox
              v-model="item.appointmentCompleted"
              color="secondary"
              :value="item.appointmentCompleted"
              @input="statusChanged(item)"
              :ripple="false"
            ></v-simple-checkbox>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PatientHistoryDialog from "@/components/common/assessment-history/PatientHistoryDialog";
import { getAppointments, updateAppointmentComplete } from "@/customApi";
import { getDeskAssessmentResult } from "@/services/modules/api-queries";
import { mapGetters } from "vuex";

export default {
  name: "ClinicalPage",
  components: {
    PatientHistoryDialog
  },
  data() {
    return {
      validUser: false,
      headers: [
        {
          text: "Email",
          value: "email.value"
        },
        {
          text: "Assessment Completed",
          value: "assessmentCompletedAt"
        },
        {
          text: "Appointment Booked",
          value: "createdAt"
        },
        {
          text: "Appointment Complete",
          value: "appointmentCompleted"
        }
      ],
      allAppointments: [],
      loading: true,
      showCompletedAppointments: true,
      showPatientReport: false,
      formattedAssessment: {},
      results: null,
      featureFlag: "None"
    };
  },
  created() {
    if (this.clinicalTeam) {
      this.validUser = true;
      this.loadAppointments();
    } else {
      this.$router.push("/wellness", () => {});
    }
  },
  computed: {
    ...mapGetters(["clinicalTeam"]),
    appointments() {
      return this.showCompletedAppointments
        ? this.allAppointments
        : this.allAppointments.filter(x => !x.appointmentCompleted);
    }
  },
  methods: {
    async loadAppointments() {
      try {
        this.allAppointments = await getAppointments();
      } catch (e) {
      } finally {
        this.loading = false;
      }
    },
    convertDate(dateString) {
      var date = new Date(dateString);
      return date.toLocaleDateString();
    },
    async statusChanged(item) {
      try {
        let dto = {
          markAsComplete: item.appointmentCompleted,
          assessmentId: item.id
        };
        await updateAppointmentComplete(dto);
      } catch (e) {
        item.appointmentCompleted = !item.appointmentCompleted;
      }
    },
    async showResults(item) {
      try {
        this.loading = true;
        this.formattedAssessment = {
          ...item,
          assessmentType: "Desk assessment",
          completedAt: item.assessmentCompletedAt
        };
        this.results = await getDeskAssessmentResult(item.id);
        this.showPatientReport = true;
      } catch (e) {
      } finally {
        this.loading = false;
      }
    },
    updateFeatureFlags(item) {
      // Temp fix. Will be replaced with company selector once this has been merged.
      var state = item === "EBRD";
      this.$store.state.featureFlags.customRecommendations_ttqWel = state;
      this.$store.state.featureFlags.team_ttqWel = state;
    }
  }
};
</script>
