var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.validUser
    ? _c(
        "v-container",
        [
          _vm.showPatientReport
            ? _c(
                "v-dialog",
                {
                  attrs: { scrollable: "" },
                  model: {
                    value: _vm.showPatientReport,
                    callback: function($$v) {
                      _vm.showPatientReport = $$v
                    },
                    expression: "showPatientReport"
                  }
                },
                [
                  _c("PatientHistoryDialog", {
                    attrs: {
                      assessment: _vm.formattedAssessment,
                      results: _vm.results
                    },
                    model: {
                      value: _vm.showPatientReport,
                      callback: function($$v) {
                        _vm.showPatientReport = $$v
                      },
                      expression: "showPatientReport"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c("v-switch", {
                    attrs: { label: "Show completed appointments" },
                    model: {
                      value: _vm.showCompletedAppointments,
                      callback: function($$v) {
                        _vm.showCompletedAppointments = $$v
                      },
                      expression: "showCompletedAppointments"
                    }
                  })
                ],
                1
              ),
              _c("v-spacer"),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c("v-select", {
                    attrs: { items: ["EBRD", "None"] },
                    on: { change: _vm.updateFeatureFlags },
                    model: {
                      value: _vm.featureFlag,
                      callback: function($$v) {
                        _vm.featureFlag = $$v
                      },
                      expression: "featureFlag"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-data-table", {
                    staticStyle: { cursor: "pointer" },
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.appointments,
                      loading: _vm.loading,
                      "sort-by": ["createdAt"],
                      "sort-desc": [true]
                    },
                    on: { "click:row": _vm.showResults },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item.assessmentCompletedAt",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.convertDate(
                                        item.assessmentCompletedAt
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            ]
                          }
                        },
                        {
                          key: "item.createdAt",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.convertDate(item.createdAt)) +
                                    " "
                                )
                              ])
                            ]
                          }
                        },
                        {
                          key: "item.appointmentCompleted",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("v-simple-checkbox", {
                                attrs: {
                                  color: "secondary",
                                  value: item.appointmentCompleted,
                                  ripple: false
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.statusChanged(item)
                                  }
                                },
                                model: {
                                  value: item.appointmentCompleted,
                                  callback: function($$v) {
                                    _vm.$set(item, "appointmentCompleted", $$v)
                                  },
                                  expression: "item.appointmentCompleted"
                                }
                              })
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }